body,
html,
.site {
  background: #24252d;
}
p {
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
}
.site {
  max-width: 960px;
  margin-left: -480px;
  left: 50%;
  height: 540px;
  margin-top: -270px;
  top: 50%;
  position: absolute;
  color: #cacacc;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 2rem;
}
.site .header {
  font-size: 5rem;
}
.site .header .erico {
  opacity: 0.5;
}
.site .subheader {
  margin-top: 0.5em;
}
.site .connect {
  margin-top: 3em;
  line-height: 1.5em;
}
.site .characters {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.site .characters .character {
  width: 23%;
  padding: 1%;
  float: left;
}
.site .characters .discarded {
  opacity: 0.3;
}
@media screen and (max-width: 1010px) {
  .site {
    margin: 1em 0.5em;
    left: 0;
    top: 0;
    position: relative;
  }
  .site .header {
    font-size: 2rem;
  }
  .site .subheader,
  .site .connect {
    font-size: 1.2rem;
  }
}
